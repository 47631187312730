import { useState } from "react";

export type GalleryItem = {
  title: string;
  imageUrl: string;
};

type ImageComponentProps = {
  image: GalleryItem;
  onClick: () => void;
};

const ImageComponent = ({ image, onClick }: ImageComponentProps) => {
  return (
    <div
      className="fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm"
      onClick={onClick}
    >
      <h1>{image.title}</h1>
      <img
        className="w-full h-full object-contain"
        src={process.env.PUBLIC_URL + image.imageUrl}
      />
    </div>
  );
};

export const ItemsGallery = ({ images: items }: { images: GalleryItem[] }) => {
  const [itemToDisplay, setItemToDisplay] = useState<GalleryItem | null>(null);
  const handleOnClickItem = (image: GalleryItem) => {
    setItemToDisplay(image);
    document.body.style.overflow = "hidden";
  };
  const closeOverlay = () => {
    setItemToDisplay(null);
    document.body.style.overflow = "auto";
  };
  return (
    <div className="bg-red-100 p-12">
      <h1 className="text-center text-2xl pb-10">Bild Galleri</h1>
      <div className="flex flex-row flex-wrap gap-6 align-middle">
        {items.map((image, index) => (
          <div
            key={index}
            onClick={() => handleOnClickItem(image)}
            className="overflow-hidden rounded-lg border-4"
          >
            {image.title && (
              <div className="bg-green-100 p-1 font-bold rounded-sm">
                {image.title}
              </div>
            )}

            <img
              alt={image.title}
              className="object-cover h-44 w-60"
              src={process.env.PUBLIC_URL + image.imageUrl}
            />
          </div>
        ))}
      </div>
      {itemToDisplay && (
        <ImageComponent image={itemToDisplay} onClick={closeOverlay} />
      )}
    </div>
  );
};
