import { Item, Section, Menu } from "./Types";

const ItemComponent = ({ item, index }: { item: Item; index?: number }) => {
  return (
    <div className="mt-2">
      <div className="flex border-b-2 border-gray-200">
        <div className="w-full">
          {index !== undefined ? (
            <span className="font-bold">{index}. </span>
          ) : null}
          {item.title}
        </div>
        {item.price !== undefined ? (
          <div className="w-32 text-right text-pink-700">{item.price}</div>
        ) : null}
      </div>
      {item.description !== undefined ? (
        <div className="text-sm italic">{item.description}</div>
      ) : null}
      {item.highlightDescription !== undefined ? (
        <div className="font-bold">{item.highlightDescription}</div>
      ) : null}
    </div>
  );
};

const SectionDescriptionComponent = ({
  description,
}: {
  description?: string;
}) => {
  if (description === undefined) return null;
  return <div className="text-center font-bold uppercase">{description}</div>;
};

const SectionComponent = ({ section }: { section: Section }) => {
  return (
    <div>
      <h1 className="my-2 uppercase font-bold bg-red-600 text-white text-1xl text-center rounded-lg">
        {section.header}
      </h1>
      <SectionDescriptionComponent description={section.description} />
      <div
        className={`grid gap-4 grid-cols-1 ${
          section.isAlwaysSingleColumn ? "" : "sm:grid-cols-2"
        }`}
      >
        {section.items.map((item, index) => (
          <div key={index}>
            <ItemComponent
              item={item}
              index={section.isNumbered ? index + 1 : undefined}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const MenuComponent = ({ menu }: { menu: Menu }) => {
  return (
    <div className="mx-4 sm:mx-auto sm:w-2/3">
      {menu.sections.map((section, index) => (
        <SectionComponent key={index} section={section} />
      ))}
    </div>
  );
};
