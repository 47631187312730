import * as React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

export const GoogleMap = () => {
  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = React.useState(3); // initial zoom
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });

  const onClick = (e: google.maps.MapMouseEvent) => {
    setClicks([...clicks, e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {
    console.log("onIdle");
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Wrapper
        apiKey={"AIzaSyBQGKelJG-qdDoqMz-bHYSyyr9CHhL4cRA"}
        render={render}
      >
        <Map
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          style={{ flexGrow: "1", height: "100%" }}
        ></Map>
      </Wrapper>
    </div>
  );
};

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

const Map: React.FC<MapProps> = ({ style, ...options }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (map) {
      let position = {
        lat: 57.6630518650891,
        lng: 11.926490285736309,
      };
      map.setCenter(position);
      map.setZoom(16);
      let marker = new google.maps.Marker();
      marker.setOptions({
        map: map,
        position: position,
        // label: {
        //   text: "Sushi Koi",
        //   className: "font-bold",
        // },
        anchorPoint: new google.maps.Point(50, 0),
      });
      marker.addListener("click", () => {
        // window.open(
        //   "https://www.google.com/maps/search/?api=1&query=57.6630346%2C11.9242866&query_place_id=ChIJo2-bopfzT0YRWlJ8NWSlCXM",
        //   "_blank"
        // );
      });
      return () => {
        if (marker) {
          marker.setMap(null);
        }
      };
    }
  }, [map]);
  console.log("show what?", style);
  return <div ref={ref} style={style} />;
};
