export const NavComponent = () => {
  return (
    <nav className="sticky top-0 flex flex-row justify-center items-center font-sans shadow-md bg-white border-b-2 border-b-black uppercase font-semibold z-50">
      <a href="#" className="navbar-item">
        Sushi K<img alt="home" src="logo.png" className="inline-block h-8" />i
      </a>
      <a href="#menu" className="navbar-item">
        Meny
      </a>
      <a href="#gallery" className="navbar-item">
        Galleri
      </a>
      <a href="#contact" className="navbar-item">
        Kontakt
      </a>
    </nav>
  );
};
