import { GalleryItem } from "./Components/Gallery";
import { Menu } from "./Components/Menus/Types";
import { Restaurant } from "./Components/Restaurant";

const menu: Menu = {
  sections: [
    {
      header: "Lunch 130kr, drick ingår (Endast vardagar 11.00-14.00)",
      items: [
        {
          title: "Sushi mix 11 bitar",
          description: "3 lax, 2 räka, 2 avokado, 4 california rullar",
        },
        {
          title: "Salmon & Rolls 11 bitar",
          description: "6 lax, 5 california rullar",
        },
        {
          title: "Shrimps & Crispy",
          description: "Gurka friterad räka avocado på röstad lök"
        },
        {
          title: "Panco Rullar",
          description: "Friterad räka, gurka, avocado, panerad med pancobröd chilimajonnäs"
        },
        {
          title: "Vegetarisk 11 bitar",
          description: "4 avokado, 3 tofu, 4 vegetarisk rullar",
        },
        {
          title: "Crispy california",
          description: "Gurka, avokado, seefoodröra, chilimajönnes på röstad lök",
        },
        {
          title: "Crispy tofu",
          description: "Gurka, tofu, avokado, chilimajonnös, röstad lök, sesamfrö",
        },
        {
          title: "Poke Bowl",
          description: "Lax eller kyckling",
        }
      ],
    },
    {
      header: "Family och Party",
      items: [
        {
          title: "30 bitar",
          description:
            "8 lax, 5 räka, 5 avokado, 2 krabba, 10 flying salmon",
          price: "360kr",
        },
        {
          title: "40 bitar",
          description:
            "10 lax, 10 avokado, 8 räka, 2 krabba, 10 panco rullar",
          price: "455kr",
        },
        {
          title: "50 bitar",
          description:
            "14 lax, 8 räka, 8 avokado, 10 flying salmon, 10 tempura ebi",
          price: "560kr",
        },
        {
          title: "60 bitar",
          description:
            "15 lax, 15 avokado, 10 räka, 10 tempura ebi, 10 special rullar",
          price: "660kr",
        },
        {
          title: "30 bitar rolls",
          description: "Maki: 3 valfria KOI rolls, 10 maki av varje",
          price: "400kr",
        },
        {
          title: "50 bitar rolls",
          description: "Maki: 5 valfria KOI rolls 10 maki av varje",
          price: "610kr",
        },
        {
          title: "60 bitar",
          description: "Maki: 6 valfria KOI rolls 10 maki av varje",
          price: "780kr",
        },
      ],
    },
    {
      header: "Salmon och Avokado",
      items: [
        {
          title: "8 bitar",
          description: "4 lax, 4 avokado",
          price: "120kr",
        },
        {
          title: "11 bitar",
          description: "6 lax, 5 avokado",
          price: "139kr",
        },
        {
          title: "14 bitar",
          description: "7 lax, 7 avokado",
          price: "155kr",
        },
      ],
    },
    {
      header: "Salmon och Rolls",
      items: [
        {
          title: "8 bitar",
          description: "4 lax, 4 rullar",
          price: "120kr",
        },
        {
          title: "11 bitar",
          description: "6 lax, 5 rullar",
          price: "139kr",
        },
        {
          title: "14 bitar",
          description: "7 lax, 7 rullar",
          price: "155kr",
        },
      ],
    },
    {
      header: "Koi Rolls",
      items: [
        {
          title: "Special Koi",
          description: "Friterad räkor, gurka, avokado, topping med halstrad lax och chilimayo, teriyakisås",
          price: "155kr"
        },
        {
          title: "California",
          description: "Gurka, avokado, seafoodröra",
          price: "130kr",
        },
        {
          title: "Crispy California",
          description: "Gurka, avokado, seafoodröra, chilimajonnäs, röstad lök",
          price: "130kr",
        },
        {
          title: "Crispy Tofu",
          description:
            "Gurka, avokado, tofu, chilimajonnäs, röstad lök, sesamfrö",
          price: "130kr",
        },
        {
          title: "Shrimps & Crispy",
          description: "Gurka, friterad räka, avokado, på röstad lök, chilimajonnäs",
          price: "145kr"
        },
        {
          title: "Mango Rullar",
          description: "Lax, gurka, avokado, topping mango, teriyakisås",
          price: "145kr",
        },
        {
          title: "Rainbow Rullar",
          description:
            "Gurka, avokado, sallad, topping lax, räka, tonfisk, bläckfisk chilimajonnäs",
          price: "145kr",
        },
        {
          title: "Flying Salmon",
          description:
            "Lax, avokado, sallad, rödlök, topping lax, chilimajonnäs, gräslök",
          price: "145kr",
        },
        {
          title: "Philadelphia",
          description:
            "Gurka, avokado, seafoodröra, philadelphia ost, topping lax",
          price: "145kr",
        },
        {
          title: "Koi Salmon",
          description:
            "Gurka, avokado, seafoodröra, topping halstrad lax, teriyakisås, chilimajonnäs, röstad lök",
          price: "150kr",
        },
        {
          title: "Yakiniku Roll",
          description: "Yakiniku, sallad, rödlök, teriyakisås och chilisås",
          price: "140kr",
        },

        {
          title: "Spicy Rullar",
          description:
            "Gurka, avokado, sallad, topping lax och tonfisk spicy sås",
          price: "140kr",
        },
        {
          title: "Special Rullar",
          description:
            "Gurka, avokado, philadelphia ost, räka, topping halstrad lax, chilimajonnäs, teriyakisås, röstad lök",
          price: "155kr",
        },

        {
          title: "Maki Salmon-Avokado",
          description: "Avokado, salmon, chilimajonnäs",
          price: "135kr",
        },
      ],
    },

    {
      header: "Tempura Rullar",
      items: [
        {
          title: "Spicy Tempura",
          description:
            "Marineradtonfisk, gurka, sallad, koriander, panerad med ströbröd",
          price: "145kr",
        },

        {
          title: "Tempura Ebi",
          description: "Tempuraräkor, gurka, topping avokado, chilimajonnäs",
          price: "145kr",
        },

        {
          title: "Panco Rullar",
          description:
            "Friterad räka, gurka, avokado, panerad med pancobröd chilimajonnäs",
          price: "145kr",
        },

        {
          title: "Tempura Yakiniku",
          description: "Entrecote, sallad, koriander, ost, gurka, chilisås",
          price: "145kr",
        },

        {
          title: "Chicken Roll",
          description: "Friterad kyckling, topping avokado, chilimayo",
          price: "145kr",
        },

        {
          title: "Crispy California (friterad)",
          description: "Gurka, avokado, seafoodröra, panerad med ströbröd",
          price: "145kr",
        },

        {
          title: "Vegan Ebi",
          description: "Gurka, friterad tofu, topping avokado chilimayo",
          price: "140kr",
        },
        {
          title: "Panco Kyckling",
          description: "Friterad kyckling, gurka, avokado, panerad med pancobröd chilimajonnäs",
          price: "145kr"
        }
      ],
    },
    {
      header: "Nigiri Lax",
      items: [
        {
          title: "8 lax",
          price: "125kr",
        },
        {
          title: "9 lax",
          price: "135kr",
        },
        {
          title: "10 lax",
          price: "140kr",
        },
        {
          title: "11 lax",
          price: "145kr",
        },
        {
          title: "13 lax",
          price: "150kr",
        },
        {
          title: "15 lax",
          price: "180kr",
        },
        {
          title: "18 lax",
          price: "210kr",
        },
        {
          title: "20 lax",
          price: "240kr",
        },
        {
          title: "25 lax",
          price: "300kr",
        },
      ],
    },
    {
      header: "vegetarisk",
      isNumbered: false,
      items: [
        {
          title: "8 bitar. 3 avokado, 2 tofu, 3 rullar",
          price: "115kr",
        },
        {
          title: "11 bitar. 4 avokado, 3 tofu, 4 rullar",
          price: "130kr",
        },
        {
          title: "13 bitar. 4 avokado, 4 tofu, 5 rullar",
          price: "140kr",
        },
        {
          title: "15 bitar. 5 avokado, 5 tofu, 5 rullar",
          price: "150kr",
        },
      ],
    },
    {
      header: "Poke Bowl",
      isAlwaysSingleColumn: true,
      isNumbered: true,
      items: [
        {
          title: "Poke Bowl",
          description:
            "Ris, mango, sallad, gurka, morot, avokado, soyabönor, böngroddar, majs, rödkål",
          highlightDescription:
            "Välj mellan lax, räka, kyckling, tofu (vanlig eller grillad)",
          price: "140kr",
        },
        {
          title: "Coconut Bowl",
          description:
            "Ris, mango, sallad, gurka, morot, avokado, soyabönor, böngroddar, majs, rödkål",
          highlightDescription:
            "Välj mellan lax, räka, kyckling, tofu",
          price: "140kr",
        },
        {
          title: "Tempura Bowl",
          description:
            "Tempuraräkor, ris, mango, sallad, gurka, morot, rödkål, avokado, soyabönor, böngroddar, chilimajonnäs",
          price: "140kr",
        },
        {
          title: "Poke Crunchy Chicken",
          description:
            "Kyckling, nachochips, morot, rödkål, gurka, böngroddar, majs, mango, soyabönor, chilimajonnäs",
          price: "140kr",
        },
        {
          title: "Bowl Yakiniku",
          description:
            "Ris, mango, salladmix, gurka, morot, avokado, soyabönor, böngroddar, rödkål, nötkött kimchi",
          price: "140kr",
        },
      ],
    },
    {
      header: "Varm Rätter",
      isAlwaysSingleColumn: true,
      isNumbered: true,
      items: [
        {
          title: "Yakiniku",
          price: "135kr",
          description:
            "Skivat nötkött, jasminris, salladmix, teriyakisås chilisås",
        },
        {
          title: "Yakitory",
          price: "130kr",
          description:
            "Kycklingspett, jasminris, salladmix, teriyakisås chilisås",
        },
        {
          title: "Bibimbap",
          price: "145kr",
          highlightDescription: "Välj mellan kött-kyckling-tofu",
          description:
            "Morot, gurka, sallad, soyabönor, kimchi, sjögrässalad, böngroddar, ris och chilisås topping stekt ägg och sesam",
        },
        {
          title: "Bento",
          price: "145kr",
          description:
            "Skivat nötkött, kycklingspett, grönsaker mix, jasminris 4 bitar sushi, 1 lax, 1 räka, 2 rullar och chilisås teriyakisås",
        },
        {
          title: "Dumpling",
          description: "8 st 115kr, 12 st 145kr, 16 st 175kr",
          highlightDescription:
            "Välj mellan kyckling-räka-vegetarisk, salladmix, sås eller dumpling all in",
        },

        {
          title: "Yakisoba",
          description: "Ramen nudlar med yakisoba sås entrecote",
          price: "165kr",
        },
      ],
    },
    {
      header: "Sushi Mix",
      isNumbered: true,
      items: [
        {
          title: "9 bitar",
          price: "120kr",
          description: "3 lax, 2 avokado, 1 räka, 3 california rullar",
        },
        {
          title: "11 bitar",
          price: "139kr",
          description: "3 lax, 2 avokado, 2 räka, 4 california rullar",
        },
        {
          title: "13 bitar",
          price: "150kr",
          description:
            "5 lax, 2 avokado, 2 räka, 4 california rullar",
        },
        {
          title: "15 bitar",
          price: "170kr",
          description:
            "5 lax, 2 räkor, 2 avokado, 1 krabba, 5 california rullar",
        },
        {
          title: "18 bitar",
          price: "210kr",
          description:
            "5 lax, 3 avokado, 3 räkor, 1 krabba, 6 california rullar", //?
        },
        {
          title: "20 bitar",
          price: "220kr",
          description:
            "6 lax, 3 avokado, 3 räka, 1 krabba, 7 california rullar",
        },
      ],
    },
    {
      header: "Side Orders",
      description: "Välj mellan lax, räka, avokado och tofu omelete",
      isNumbered: true,
      items: [
        {
          title: "Ingefära",
          price: "40kr"
        },
        {
          title: "Gome Wakame",
          price: "40 kr",
        },
        {
          title: "Edamame",
          price: "40 kr",
        },
        {
          title: "Kimchi",
          price: "40 kr",
        },
        {
          title: "Tempura shrimps 2st",
          price: "40 kr",
        },
        {
          title: "Tempura shrimps 4st",
          price: "65 kr",
        },
        {
          title: "Sauce / Dip",
          price: "10 kr",
        },
        {
          title: "Add Nigiris",
          price: "30 kr",
        },
      ],
    },
  ],
};

export const galleryItems: GalleryItem[] = [
  { title: "", imageUrl: "/images/1.jpg" },
  { title: "", imageUrl: "/images/2.jpg" },
  { title: "", imageUrl: "/images/3.jpg" },
  { title: "", imageUrl: "/images/4.jpg" },
  { title: "", imageUrl: "/images/5.jpg" },
  { title: "", imageUrl: "/images/6.jpg" },
  { title: "", imageUrl: "/images/7.jpg" },
  { title: "", imageUrl: "/images/8.jpg" },
  { title: "", imageUrl: "/images/9.jpg" },
  { title: "", imageUrl: "/images/10.jpg" },
  { title: "", imageUrl: "/images/11.jpg" },
  { title: "", imageUrl: "/images/12.jpg" },
  { title: "", imageUrl: "/images/13.jpg" },
  { title: "", imageUrl: "/images/14.jpg" },
  { title: "", imageUrl: "/images/15.jpg" },
];

export const restaurant: Restaurant = {
  gallery: galleryItems,
  aboutUs: {
    address: {
      street: "Famngatan 2",
      zipCode: "421 70",
      city: "Göteborg",
      telephone: "070 459 28 34",
      location: {
        lat: 0,
        lng: 0,
      },
    },
    timeTable: {
      daysToTime: [
        { day: "Måndag", startToEnd: "Stängt" },
        { day: "Tisdag-Törsdag", startToEnd: "11.00-20.00" },
        { day: "Fridag", startToEnd: "11.00-21.00" },
        { day: "Lördag", startToEnd: "11.30-21.00" },
        { day: "Söndag", startToEnd: "12.00-20.00" }
      ],
    },
  },
  menu: menu,
};
