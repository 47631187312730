import Map from "./Map";
import { GoogleMap } from "./Map2";

export type Address = {
  street: string;
  zipCode: string;
  city: string;
  telephone: string;
  location: {
    lat: number;
    lng: number;
  };
};

export type SocialMedia = {
  instagram: string;
  facebook: string;
};

export type TimeTable = {
  daysToTime: { day: string; startToEnd: string }[];
};

export type AboutUs = {
  address: Address;
  timeTable: TimeTable;
  socialMedia?: SocialMedia;
};

export const AboutUsComponent = ({
  aboutUs: { address, timeTable },
}: {
  aboutUs: AboutUs;
}) => {
  const { street, zipCode, city, telephone } = address;
  return (
    <div className="mx-8">
      <h1 className="text-4xl text-center py-12 font-bold">Om oss</h1>
      <div className="sm:flex justify-evenly gap-4 mb-8">
        <div>
          <h1 className="text-2xl font-bold mb-4">Öppettider</h1>
          {timeTable.daysToTime.map(({ day, startToEnd }, index) => (
            <div className="flex flex-row" key={index}>
              <div className="w-36">{day}</div>
              <div className="w-36 text-red-500 font-bold">{startToEnd}</div>
            </div>
          ))}
        </div>
        <div>
          <h1 className="text-2xl font-bold mb-4">Kontakta oss</h1>
          <div className="italic">
            Address: {street}, {zipCode} {city}
          </div>
          <div>
            <h1>Telephone:</h1>
            <div>
              <a className="block underline" href={`tel:${telephone}`}>
                {telephone}
              </a>
            </div>
          </div>
        </div>
        <div>
          <h1 className="text-2xl font-bold mb-4">Social Media</h1>
          <div className="flex flex-row gap-4">
            <div className="w-14">
              <a href="https://www.facebook.com/SushiKoiRestaurang">
                <img
                  alt="instagram"
                  src={process.env.PUBLIC_URL + "/instagram.png"}
                />
              </a>
            </div>
            <div className="w-14">
              <a href="https://www.facebook.com/SushiKoiRestaurang">
                <img
                  alt="facebook"
                  src={process.env.PUBLIC_URL + "/facebook.png"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="h-72">
        <GoogleMap />
      </div>
    </div>
  );
};
